import { Component, OnInit } from '@angular/core';
import { UsersPageService } from '../../../../services/users/users.service';
import { map } from 'rxjs';
import { DialogService } from '../../../../services/dialog/dialog.service';
import { InviteUserPopupComponent } from './components/invite-user-popup/invite-user-popup.component';
import { DialogComponent } from '../../../../components/dialog/dialog.component';
import { CreateUserPopupComponent } from './components/create-user-popup/create-user-popup.component';
import { EditUserPopupComponent } from './components/edit-user-popup/edit-user-popup.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderService } from '../../../../services/loader/loader.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss', '../../main.module.styles.scss']
})
export class UsersComponent implements OnInit{

  constructor (
    private usersPageService: UsersPageService,
    private dialog: DialogService,
    private dialog1: MatDialog,
  ) {}

  public users: any[] = [];
  public filteredUsers: any[] = [];
  public filterText: string = ''; 

  public columns = [
    { field: 'fullName', header: 'Name' },
    { field: 'userActivityStatus', header: 'Status' },
    { field: 'roles', header: 'Role' },
    { field: 'email', header: 'Email address' },
    { field: 'phone', header: 'Phone' },
    { field: 'command', header: 'Actions' },
  ];

  ngOnInit(): void {
    this.getUsers();
    window.onresize = (e) => this.getNumbersOfRows();
  }
  
  onFilterChange(value: string) {
    this.filterText = value;
    this.applyFilter();
  }

  applyFilter() {
    if (this.filterText.length > 2) {
      const filterTextLower = this.filterText.toLowerCase();
  
      this.filteredUsers = this.users.filter((item) => {
        // Check if filterText is 'active' or 'inactive'
        if ('active'.includes(filterTextLower)) {
          return item.userActivityStatus === true;
        }
        if ('inactive'.includes(filterTextLower)) {
          return item.userActivityStatus === false;
        }
  
        // Generic search across all fields including nested fields
        const matches = (obj: any): boolean => {
          return Object.values(obj).some((value) => {
            if (typeof value === 'string') {
              return value.toLowerCase().includes(filterTextLower);
            } else if (typeof value === 'object' && value !== null) {
              return matches(value);
            }
            return false;
          });
        };
        return matches(item);
      });
    } else {
      this.filteredUsers = [...this.users];
    }
  }

  getNumbersOfRows(): number {
    const tableWrapper: HTMLElement = document.querySelector('.wrapper2') as HTMLElement;
    const estimatedRows: number = Math.trunc((tableWrapper.clientHeight - (68+55.5)) / 57)
    return estimatedRows < 1 ? 1 : estimatedRows;
  }

  addUserClick() {
    this.openAddUserPopup();
  }

  inviteUserClick() {
    this.usersPageService.getSupervisors().subscribe(superVisorsSource => {
      this.openInvitePopup(superVisorsSource);
    },
    error => {
      console.error('Error fetching users', error);
    });
  }

  openAddUserPopup() {

    let dialogRef: MatDialogRef<CreateUserPopupComponent> = this.dialog1.open(CreateUserPopupComponent);

    const sub = dialogRef.componentInstance.onConfirm.subscribe((data: any) => {
      this.addUser(data).then(result=> {
        this.getUsers();
        let succesText: string = result.firstName + ' ' + result.lastName + ' was added to users page. Invite link was sent to '
        this.openSuccesInviteOrAddDialog(result, dialogRef, 'Create new user', succesText, result.email);
      }).catch(error => {
        console.error(error);
      });
    });

    dialogRef.afterClosed().subscribe(result => {
      sub.unsubscribe();
    });
  }

  openInvitePopup(superVisorsSource: Array<any>) {
    let dialogRef: MatDialogRef<InviteUserPopupComponent> = this.dialog1.open(InviteUserPopupComponent, {
      panelClass: 'custom-container',
      data: {superVisorsSource: [...superVisorsSource]}
    });
    
    const sub = dialogRef.componentInstance.onConfirm.subscribe((data: any) => {
      this.inviteUser(data).then(result=> {
        this.openSuccesInviteOrAddDialog(result, dialogRef, 'Invite new user', 'Invite link was sent to');
      }).catch(error => {
        this.openWarningDialog(error.error.message);
        console.error(error);
      });
    });

    dialogRef.afterClosed().subscribe(result => {
      sub.unsubscribe();
    });
  }

  getUsers() {
    this.usersPageService.getUsers().pipe(
      map(data => {
        return data.map(user => ({
          ...user,
          fullName: `${user.firstName} ${user.lastName}`,
          userActivityStatus: user.activityStatus
        }));
      })
    ).subscribe(
      transformedData => {
        this.users = transformedData;
        this.applyFilter();
      },
      error => {
        console.error('Error fetching users', error);
      }
    );
  }

  addUser(data: Array<any>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.usersPageService.addUser(data).subscribe(res => {
        this.getUsers();
        resolve(data);
      }, error => {
        reject(error);
      })
    });
  }

  editUser(data: Array<any>, userID: number):Promise<any>  {
    return new Promise<any>((resolve, reject)=>{
      this.usersPageService.editUser(data, userID).subscribe((res) => {
        resolve(res);
      },(error) => {
        console.log(error);
        reject(error);
      }
      );
    });
  }

  inviteUser(data: Array<any>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.usersPageService.inviteUser(data).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject(error);
      })
    })
  }

  openSuccesInviteOrAddDialog(res: any, parentDialogRef: MatDialogRef<InviteUserPopupComponent | CreateUserPopupComponent>, button1Text: string, text?: string, emailText?: string) {
    const succesInviteIconStyle = {stroke: '#D1FADF', width: '48px', heigth: '48px'};
    const btn1Style = {background: 'white', color: '#344054', border: '1px solid #D0D5DD'};
    const btn2Style = {background: '#175CD3', color: 'white', border: '1px solid #175CD3'};
    let emailList;
    if(parentDialogRef.componentInstance instanceof InviteUserPopupComponent) emailList = res.map((item:any) => item.email).join(',').split(",").join("\n");
    
    const dialogData: any = {
      title: 'Success',
      text: text,
      button1Text: button1Text,
      button2Text: 'Confirm',
      icon: 'green-succes',
      iconStyle: succesInviteIconStyle,
      dsa: false,
      tooltip: emailList,
      email: emailText
    };

    const dialogRef = this.dialog.openDialog(DialogComponent, {
      props: {...dialogData, btn1Style, btn2Style}
    });

    dialogRef.subscribe(dialog => {
      if(!dialog) return;
      switch (dialog.result) {
        case 'button1': 
          if(parentDialogRef.componentInstance instanceof InviteUserPopupComponent) parentDialogRef.componentInstance.form.reset();
          if(parentDialogRef.componentInstance instanceof CreateUserPopupComponent) parentDialogRef.componentInstance.createUserForm.userForm.reset();
        break;
        case 'button2': parentDialogRef.close(); break;
      }
    });
  }

  openSuccesAddDialog(res: any, parentDialogRef: MatDialogRef<CreateUserPopupComponent>) {
    const succesInviteIconStyle = {stroke: '#D1FADF', width: '48px', heigth: '48px'};
    const btn1Style = {background: 'white', color: '#344054', border: '1px solid #D0D5DD'};
    const btn2Style = {background: '#175CD3', color: 'white', border: '1px solid #175CD3'};
    
    const dialogData: any = {
      title: 'Success',
      text: 'User has been added succesfully',
      button1Text: 'Add new user',
      button2Text: 'Confirm',
      icon: 'green-succes',
      iconStyle: succesInviteIconStyle,
    };

    const dialogRef = this.dialog.openDialog(DialogComponent, {
      props: {...dialogData, btn1Style, btn2Style}
    });

    dialogRef.subscribe(dialog => {
      if(!dialog) return;
      switch (dialog.result) {
        case 'button1': parentDialogRef.componentInstance.resetForm(); break;
        case 'button2': parentDialogRef.close(); break;
        default: break;
      }
    });
  }

  openWarningDialog(msg: string) {
    const warningIconStyle = {stroke: '#FEF0C7', width: '48px', heigth: '48px'};
    const btn2Style = {background: '#175CD3', color: 'white', border: '1px solid #175CD3'};
    
    const dialogData: any = {
      title: 'Warning',
      text: msg,
      button2Text: 'Confirm',
      icon: 'warning-icon',
      iconStyle: warningIconStyle,
    };

    const dialogRef = this.dialog.openDialog(DialogComponent, {
      props: {...dialogData, btn2Style}
    });

    dialogRef.subscribe(dialog => {
      if(!dialog) return;
      switch (dialog.result) {
        case 'button2': break;
        default: break;
      }
    });
  }

  editRow(row: any) {
    this.openEditUserPopup(row);
  }

  openEditUserPopup(row: any) {
    const dialogRef = this.dialog1.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;

    componentInstance.subscribe({data: row},  (popup:any) => { 
      switch(popup.type) {
        case "close" : componentInstance.close(); break;
        case "deactivate": this.deactivateHandler(row, componentInstance); break;
        case "ok": this.confirmHandler(popup, row, componentInstance); break;
      }
    });
  }

  deactivateHandler(row: any, parentDialogRef: any) {
    const text:string = 'Are you sure you want to deactivate ' + row.fullName + ' ? This action cannot be undone.'

    this.openDeleteDialog(text).then(res=>{
      if(!res) return;
        this.deleteRow(row, parentDialogRef);
    });
  }

  confirmHandler(popup: any, row: any, componentInstance:any) {
    this.editUser(popup.formData, row.id).then(()=> this.getUsers(), componentInstance.close());
  }

  deleteRow(row: any, parentDialogRef?:any) {
    // const dontShowAgain: string | null = localStorage.getItem('dsa');
    // let dontShowAgainFlag: boolean | null = false;
    // if(dontShowAgain) dontShowAgainFlag = JSON.parse(dontShowAgain);
    // if(!dontShowAgainFlag || !dontShowAgain) 
    // this.openDeleteDialog(row, deleteText, parentDialogRef);
    // else {

      this.usersPageService.deleteUser(row.id).subscribe(()=>{
        if(parentDialogRef) parentDialogRef.close();
        this.getUsers();
      }, error =>{
        console.log(error);
      });

    // }
  }

  openDeleteDialog(deleteText: string):Promise<boolean> {
    return new Promise<any>((resolve, reject)=>{
      const deletIconStyle = {stroke: '#D92D20', width: '48px', heigth: '48px'};
      const btn1Style = {background: 'white', color: '#344054', border: '1px solid #D0D5DD'};
      const btn2Style = {background: '#D92D20', color: 'white', border: '1px solid #D92D20'};

      const dialogData: any = {
        title: 'Deactivate',
        text: deleteText,
        button1Text: 'Cancel',
        button2Text: 'Delete',
        icon: 'custom-trash',
        iconStyle: deletIconStyle,
        dsa: true
      };

      const dialogRef = this.dialog.openDialog(DialogComponent, {
        props: {...dialogData, btn1Style, btn2Style}
      });

      dialogRef.subscribe((dialog) => {
        if (!dialog) return;
        switch (dialog.result) {
          case 'button1': resolve(false); break;
          case 'button2': resolve(true); break;
        }
      });
    });
  }

  selectRows(selectedRows: []) {
    // console.log('Selected rows:', selectedRows);
  }

}