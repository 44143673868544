import { Node } from "../intefaces/node";

export const TREE_DATA: Node[] = [
    // {
    //   name: 'Dashboard',
    //   expandable: false,
    //   level: 0,
    //   src: 'assets/svg/dashboard-icon.svg',
    //   link: '/dashboard'
    // },
    {
      name: 'Timeline',
      expandable: false,
      level: 0,
      src: 'assets/svg/timeline-img.svg',
      link: '/timeline'
    },
    {
      name: 'Loads',
      expandable: false,
      level: 0,
      src: 'assets/svg/loads-img.svg',
      link: '/loads'
    },
    // {
    //   name: 'Map',
    //   expandable: false,
    //   level: 0,
    //   src: 'assets/svg/map-img.svg',
    //   link: '/map'
    // },
    {
      name: 'Manage',
      expandable: false,
      level: 0,
      src: 'assets/svg/manage-img.svg',
      // link: '/manage',
      children: [
        {
          name: 'Dispatch Roles',
          expandable: false,
          level: 1,
          src: 'assets/svg/manage-img.svg',
          link: '/manage/dispatch'
        },
        {
          name: 'Carrier Roles',
          expandable: false,
          level: 1,
          src: 'assets/svg/manage-img.svg',
          link: '/manage/carrier'
        }
      ]
    },
    {
      name: 'Reports',
      expandable: false,
      level: 0,
      src: 'assets/svg/reports-img.svg',
      link: '/reports'
    },
    {
      name: 'Accounting',
      expandable: false,
      level: 0,
      src: 'assets/svg/accounting-img.svg',
      children: [
        {
          name: 'Invoices',
          expandable: false,
          level: 1,
          src: 'assets/svg/accounting-img.svg',
          link: 'accounting/invoices'
        },
        {
          name: 'Salary',
          expandable: false,
          level: 1,
          src: 'assets/svg/accounting-img.svg',
          link: 'accounting/salary'
        },
      ],
    },
    // {
    //   name: 'Safety',
    //   expandable: false,
    //   level: 0,
    //   src: 'assets/svg/safety-img.svg',
    //   link: '/safety'
    // },
    // {
    //   name: 'Data Library',
    //   expandable: true,
    //   level: 0,
    //   src: 'assets/svg/data-library-img.svg',
    //   link: '/data-library',
    //   isExpanded: false,
    //   children: [
    //     {
    //       name: 'HZCE',
    //       expandable: false,
    //       level: 1,
    //       src: 'assets/svg/accounting-img.svg',
    //       link: '/trh'
    //     },
    //     {
    //       name: 'YOOO',
    //       expandable: false,
    //       level: 1,
    //       src: 'assets/svg/accounting-img.svg',
    //       link: '/gtrtr'
    //     },
    //   ]
    // },
    {
      name: 'Users',
      expandable: false,
      level: 0,
      src: 'assets/svg/users-img.svg',
      link: '/users'
    },
    {
      name: '',
      expandable: false,
      level: 0,
      src: '',
      link: ''
    },
    // {
    //   name: 'Support',
    //   expandable: false,
    //   level: 0,
    //   src: 'assets/svg/support-img.svg',
    //   link: '/support'
    // },
    // {
    //   name: 'Settings',
    //   expandable: false,
    //   level: 0,
    //   src: 'assets/svg/settings-img.svg',
    //   link: '/settings'
    // },
];